import type { PluginListenerHandle } from '@capacitor/core';

export interface BrowserPlugin {
  /**
   * Open a page with the specified options.
   */
  open(options: OpenOptions): Promise<void>;

  /**
   * Web & iOS only: Close an open browser window.
   *
   * No-op on other platforms.
   *
   */
  close(): Promise<void>;

  /**
   * Android & iOS only: Listen for the browser finished event.
   * CustomTabs view: It fires when the Browser is closed by the user.
   * Auth view: It fires when the page finished in WebView context.
   *
   */
  addListener(
    eventName: 'browserFinished',
    listenerFunc: (data: any) => void,
  ): Promise<PluginListenerHandle> & PluginListenerHandle;

  /**
   * Android & iOS only: Listen for the page loaded event.
   * -> CustomTabs view:
   * It's only fired when the URL passed to open method finish loading.
   * It is not invoked for any subsequent page loads.
   *
   * -> Auth view:
   * It fires when page loaded
   *
   */
  addListener(
    // tslint:disable-next-line:unified-signatures
    eventName: 'browserPageLoaded',
    listenerFunc: (data: any) => void,
  ): Promise<PluginListenerHandle> & PluginListenerHandle;

  /**
   * Android & iOS: Listen for the browser dismiss/cancel event.
   * -> Auth view:
   * It fires when browser is dismiss
   *
   */
  addListener(
    // tslint:disable-next-line:unified-signatures
    eventName: 'browserDismiss',
    listenerFunc: () => void,
  ): Promise<PluginListenerHandle> & PluginListenerHandle;

  /**
   * Remove all native listeners for this plugin.
   *
   */
  removeAllListeners(): Promise<void>;
}

/**
 * Represents the options passed to `open`.
 *
 * @since 1.0.0
 */
export interface OpenOptions {
  /**
   * The URL to which the browser is opened.
   *
   */
  url: string;

  /**
   * Web only: Optional target for browser open. Follows
   * the `target` property for window.open. Defaults
   * to _blank.
   *
   * Ignored on other platforms.
   *
   */
  windowName?: string;

  /**
   * A hex color to which the toolbar color is set.
   *
   */
  toolbarColor?: string;

  /**
   * iOS only: The presentation style of the browser. Defaults to fullscreen.
   *
   * Ignored on other platforms.
   *
   */
  presentationStyle?: 'fullscreen' | 'popover';

  /**
   * iOS & Android: the type of view used to display popup
   */
  view?: 'default' | 'auth';
}

/**
 * @deprecated Use `OpenOptions`.
 */
export type BrowserOpenOptions = OpenOptions;
