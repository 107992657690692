

export interface TextInputMaskInterface {
  mask: string;
  suffix?: string;
  prefix?: string;
  patterns?: {
    [character: string]: {
      pattern: RegExp;
      optional?: boolean;
      symbol?: string;
    }; };
  thousandSeparator?: string;
  decimalMarker?: '.' | ',';
  allowNegativeNumbers?: boolean;
  separatorLimit?: string;
}

export interface TextInputButtonInterface {
  icon: string;
  color?: string;
  click: (event: Event) => void;
  tooltip?: string;
}

export interface TextInputIconInterface {
  icon: string;
  color?: string;
  tooltip?: string;
  position?: 'start' | 'end';
}
